import Sentry from '../services/sentry.service';
const identity = x => x;
const getUndefined = () => { };
const filter = () => true;
const getType = action => action.type;

export const createSentryMiddleware = (options: {
  getUserContext?: (state: any) => any;
  breadcrumbDataFromAction?: (action: any) => any;
  breadcrumbMessageFromAction?: (action: any) => string;
  actionTransformer?: (action: any) => any;
  stateTransformer?: (state: any) => any;
  filterBreadcrumbActions?: (action: any) => boolean;
  breadcrumbCategory?: string;
} = {}) => {
  const {
    breadcrumbDataFromAction = getUndefined,
    breadcrumbMessageFromAction = getType,
    actionTransformer = identity,
    stateTransformer = identity,
    getUserContext,
    filterBreadcrumbActions = filter,
    breadcrumbCategory = "redux-action"
  } = options;

  return store => {
    let lastAction = null; // assigning null is a workaround since sentry api normalizes the store data and converts undefined to '[undefined]'

    Sentry.addEventProcessor((event) => {
      const state = store.getState();

      event.extra = {
        ...event.extra,
        lastAction: actionTransformer(lastAction),
        state: stateTransformer(state)
      };

      if (getUserContext) {
        event.user = { ...event.user, ...getUserContext(state) };
      }
      return event;
    });

    return next => action => {
      if (filterBreadcrumbActions(action)) {
        Sentry.addBreadcrumb({
          category: breadcrumbCategory,
          message: breadcrumbMessageFromAction(action),
          level: "info",
          data: breadcrumbDataFromAction(action)
        });
      }

      lastAction = action;
      return next(action);
    };
  };
};