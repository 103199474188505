import { Build } from '@stencil/core';
import {
  Store,
  applyMiddleware,
  compose,
  createStore
} from 'redux';
import freeze from 'redux-freeze';
import logger from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk, { ThunkDispatch } from 'redux-thunk';

import { Actions } from './actions';
import { analyticsMiddleware } from './analytics.middleware';
import { init } from './app/app.actions';
import rootReducer, { ApplicationState } from './reducers';
import { getAuthUser } from './selectors';
import { createSentryMiddleware } from './sentry.middleware';

const sentry = createSentryMiddleware({
  getUserContext: state => getAuthUser(state),
  stateTransformer: state => ({
    ...state
  })
});

const reduxMiddleware = [
  thunk,
  sentry,
  freeze,
  analyticsMiddleware
];

if (Build.isDev && !Build.isTesting) {
  reduxMiddleware.push(logger as any);
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Define this type to essentially appease the typescript lint process
export type appStore = Store<ApplicationState> & { dispatch: ThunkDispatch<ApplicationState, undefined, Actions> };

export const initialStore: appStore = createStore(rootReducer, undefined, composeEnhancers(
  applyMiddleware(...reduxMiddleware)
));

export const persistor = () => persistStore(initialStore, {}, () => {
  // tslint:disable-next-line: no-unbound-method
  init(initialStore.dispatch, initialStore.getState);
});
